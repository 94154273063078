import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter as Router, Route} from "react-router-dom";
import * as serviceWorker from "./serviceWorker";

import {Provider} from "mobx-react";

import "./index.css";
import pages from "./pages";

ReactDOM.render(
    <Provider>
        <Router>
            <div>
                {
                    pages.map((page, key) => <Route key={key} {...page}/>)
                }
            </div>
        </Router>
    </Provider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
